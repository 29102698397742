import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Card, Container, Row, Col } from 'react-bootstrap';
import Title from '../SectionTitle';
// import AboutImg from '../gatsbyImage/AboutImg';
// import ProjectImg from '../gatsbyImage/ProjectImg';
// import yannis from '../../assets/images/profiles/profile0.jpg';
// import yannis1 from '../../assets/images/profile1.jpg';
import config from '../../../config';

export default function Experience() {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <div>
      <section id="education" className="education-section">
        <Container className="education-wrapper">
          <Title title="Education" />
          {config.education.map(
            edu => {
              const { img, image_alt, school, more, years, gpa, explanation } = edu;
              return (
                <Fade bottom duration={1000} delay={600} distance="30px">
                  <Row >
                    <Col md={4} sm={12}>
                      <img
                        src={img}
                        className="education-wrapper__image" alt={image_alt} />
                    </Col>
                    <Col md={8} sm={12} className="education-wrapper__info-text">
                      <Card>
                        <Card.Body>
                          <Card.Title ><b>{school}</b></Card.Title>
                          <Card.Subtitle className="mb-2"><i>{more}</i></Card.Subtitle>
                          <Card.Subtitle className="mb-2 text">{years}</Card.Subtitle>
                          <Card.Text className="mb-2 text">
                            {gpa} / 4.00 GPA
                          </Card.Text>
                          <Card.Text className="mb-2 text">
                            {explanation}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Fade>
              );
            })}
        </Container>
      </section>
    </div>
  );
};