import React from 'react';

import Layout from '../components/Layout';
import Hero from '../components/index/Hero';
import About from '../components/index/About';
import Experience from '../components/index/Experience';
import Education from '../components/index/Education';
import More from '../components/index/More';

export default function IndexPage() {
  return (
    <Layout>
      <Hero />
      <About />
      <Education/>
      <Experience/>
      <More/>
    </Layout>
  )
}
