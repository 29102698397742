import React from 'react';
import config from '../../../config';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import Title from '../SectionTitle';

export default function FooterContact() {
  return (
    <div>
      <section id="footer-contact">
        <Container>
          <Title title="More!" />
          <Fade bottom duration={1000} delay={800} distance="30px">
            <div className="footer-contact-button">
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="cta-btn cta-btn--resume"
                href={`mailto:${config.email}`}
              >
                Contact Yannis
            </a>
            </div>
          </Fade>
        </Container>
      </section>
    </div>
  );
}
