import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../SectionTitle';
import AboutImg from '../gatsbyImage/AboutImg';
import config from '../../../config';

export default function About() {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <div>
      <section id="about" className="about-section">
        <Container>
          <Title title="About Me" />
          <Row className="about-wrapper" className="about-wrapper">
            <Col md={4} sm={12}>
              <Fade bottom duration={1000} delay={600} distance="30px">
                <div className="thumbnail rounded">
                  <AboutImg alt="profile picture" filename={'profile3.jpg'} />
                </div>
                {/* className="thumbnail rounded" */}
                {/* <img alt="profile picture" src={yannis} className="about-wrapper__image"/> */}
              </Fade>
            </Col>
            <Col md={8} sm={12} className="about-wrapper__info">
              {/* <div className="about-wrapper__info"> */}
              <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
                <p className="about-wrapper__info-text">
                  My name is Yannis and I am an ambitious and resourceful Software Developer. I graduated with a dual degree in
                  Computer Science and Economics at the University of Massachusetts Amherst Commonwealth Honors College.
                </p>
                <p className="about-wrapper__info-text">
                  I am interested in financial technologies and web development.
                  I have experience with agile software development and enterprise desktop support. Feel free to reach out to me!
                </p>
                <span className="d-flex mt-3">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cta-btn cta-btn--resume"
                    href={config.resume}>
                    Resume
                  </a>
                </span>
              </Fade>
              {/* </div> */}
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};