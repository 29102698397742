import React, { Component } from 'react';
import Typing from './Typing';

import white from '../../assets/images/logos/white.png';

export default class Hero extends Component {
  render() {
    return (
      <header className="masthead">
        <div className="container d-flex h-100 align-items-center">
          <div className="mx-auto text-center">
            <img src={white} className="img-fluid col-lg-6" alt="YL Logo" />
            <h1 className="text-uppercase">Yannis Lam</h1>
            <Typing />
            <div className="toparrow">
              <div className="arrow bounce">
                <a className="fa fa-angle-down fa-2x" href="#about"></a>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
