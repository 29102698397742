import React, { useState, useEffect } from 'react';
import Typist from 'react-typist';

export default function Typing() {

    const [count, setCount] = useState(1);

    useEffect(() => {
        setCount(1);
    }, [count]);

    return (
        <section id="typing">
            <h3 className="text-white mx-auto mt-2 mb-5">
                {count ? (<Typist cursor={{ blink: true }} onTypingDone={() => (setCount(0))}>
                    <span>I am a </span>
                    <span>Computer Scientist</span>
                    <Typist.Backspace count={20} delay={1000} />
                    <span>an Economist</span>
                    <Typist.Backspace count={11} delay={1000} />
                    <span> Software Developer</span>
                    <Typist.Backspace count={19} delay={1000} />
                    <span> Photographer</span>
                    <Typist.Backspace count={14} delay={1000} />
                    <span>Yannis</span>
                    <Typist.Backspace count={0} delay={1000} />
                </Typist>) : (
                        "I am a "
                    )}
            </h3>
        </section>
    );
};
