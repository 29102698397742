import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Card, Container, Row, Col } from 'react-bootstrap';
import Title from '../SectionTitle';
// import AboutImg from '../gatsbyImage/AboutImg';
// import ProjectImg from '../gatsbyImage/ProjectImg';
// import yannis from '../../assets/images/profiles/profile0.jpg';
import config from '../../../config';

export default function Experience() {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <div>
      <section id="experience" className="experience-section" >
        <Container className="experience-wrapper">

          <Title title="Experience" />
          {config.experience.map(
            exp => {
              const { img, link, work, title, about, years, explanation } = exp;
              return (
                <Fade bottom duration={1000} delay={600} distance="30px">
                  <Row >
                    <Col md={4} sm={12}>
                      {img &&
                        <a href={link} target="_blank">
                          <img src={img} className="experience-wrapper__image" alt={work} />
                        </a>
                      }
                    </Col>
                    <Col md={8} sm={12} className="experience-wrapper__info-text">
                      <Card>
                        <Card.Body>
                          {work &&
                            <Card.Title ><b>{work}</b></Card.Title>
                          }
                          <Card.Subtitle className="mb-2"><i>{title}</i></Card.Subtitle>
                          <Card.Subtitle className="mb-2 text">{years}</Card.Subtitle>
                          <Card.Text className="mb-1 text">
                            {about}
                          </Card.Text>
                          <Card.Text className="mb-2 text">
                            {explanation}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Fade>
              );
            })}
        </Container>
      </section>

    </div>
  );
};